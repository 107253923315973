import Vue from "vue";
import VueRouter from "vue-router"
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('../pages/home')
    },
    // {
    //     path: '/home',
    //     name: 'Index',
    //     component: () => import('../pages/index')
    // },
    {
        path: '/company',
        name: 'Company',
        component: () => import('../pages/company')
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import('../pages/product')
    },
    {
        path: '/shipper',
        name: 'Shipper',
        component: () => import('../pages/shipper'),
        children: [
            {
                path: '/shipper/join',
                name: 'Join',
                component: () => import('../pages/shipper/join')
            },
            
        ]
    },
    {
        path: '/driver',
        name: 'Driver',
        component: () => import('../pages/driver')
    },
    {
        path: '/shipper/rz',
        name: '司机认证',
        component: () => import('../pages/shipper/renzheng')
    },
]
const router = new VueRouter({
    routes
})
//防止重复点击
let pushPlugin = VueRouter.prototype.push

VueRouter.prototype.push = function(config) {
    return pushPlugin.call(this, config).catch(() => {
        console.log('点击了重复路径')
    })
}
//路由拦截
router.beforeEach((to, from, next) => {
    // to and from are both route objects. must call `next`.
    next()
})
export default router