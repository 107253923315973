const state = {
    userInfo: {},
    token: '',
    typeList: []
}

const getters = {
    getUserInfo: state => state.userInfo,
    getToken: state => state.token,
    getTypeList: state => state.typeList
}

const actions = {
    setUserInfo({commit}, user) {
        commit('mutationUserInfo', user)
    },
    setToken({commit}, token) {
        commit('mutationToken', token)
    },
    setTypeList({commit}, list) {
        commit('mutationTypeList', list)
    }
}

const mutations = {
    mutationUserInfo(state, user) {
        state.userInfo = user
    },
    mutationToken(state, token) {
        state.token = token
    },
    mutationTypeList(state, list) {
        state.typeList = list
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}