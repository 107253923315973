import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex)
import commom from "./commom";
const store = new Vuex.Store({
    modules: {
        commom
    }
})

export default store